const IconPlus = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" fill="none">
        <path
            d="M31 16.6562H18.3438V4H16.6562V16.6562H4V18.3438H16.6562V31H18.3438V18.3438H31V16.6562Z"
            fill="currentColor"
        />
    </svg>
);

export default IconPlus;
